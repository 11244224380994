import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { hr } from 'framer-motion/client';

// i18next.use(LanguageDetector).init({
//   supportedLngs: ['en', 'nl', 'es'],
// });
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          welcome: "Hi, I'm ",
          intro: "Looking for fun, creative projects to collaborate on with you!",
          aboutHeader: "About Me",
          aboutText: "I’m enthusiastic about a wide range of creative projects. Whether it’s video production, writing, web development, or something entirely different, I bring a mix of journalistic skills, creativity, and a slightly excessive love for geography trivia. Let’s make something amazing together!",
          contactHeader: "Get in Touch",
          contactSubheader: "Let's work together!",
          contactText: "Do you have an idea — whether it’s just a spark or a fully fleshed-out project — where you think: I want Wouter to be part of this? Send me a message using the form below or email me at ",
          formName: "Name",
          formNamePlaceholder: "Your name",
          formEmail: "Email",
          formEmailPlaceholder: "Your email",
          formMessage: "Message",
          formMessagePlaceholder: "Your message",
          formSubmit: "Send Message",
          specialties: {
            video: {
              title: "Video",
              description: "For NOS op 3, I created around 100 \"mini-explainers\" for <1>Instagram</1> and TikTok. I can write, film, and edit, and I’m also learning motion design.",
              href1: "https://www.instagram.com/nosop3"
            },
            writing: {
              title: "Writing",
              description: "I enjoy explaining complex topics in a clear and engaging way — whether it’s about the <1>nitrogen crisis</1>, Dutch provincial elections, Formula 1 or the <2>James Webb Space Telescope</2>.",
              href1: "https://app.nos.nl/op3/stikstof-uitgelegd/#/",
              href2: "https://app.nosdigital.dev/op3/james-webb/#/",
            },
            web: {
              title: "Web",
              description: "I’ve built viral websites like <1>Woonplaatsguesser</1> (played 2 million times in one weekend) and <2>CatGPT</2>, a chatbot that only responds with \"meow.\"",
              href1: "https://www.woonplaatsguesser.nl",
              href2: "https://catgpt.wvd.io",
            },
            podcast: {
              title: "Podcast",
              description: "I co-created the podcast <1>Follow the Facts</1>,  about fact-checking, OSINT and social media. I'm also producing and co-hosting <2>Achter de Pyreneeën</2>, a podcast about Spain and Portugal.",
              href1: "https://open.spotify.com/show/4p9yGVEayV0swxrOWQGjpz",
              href2: "https://creators.spotify.com/pod/show/achter-de-pyreneeen",
            },
            data_ai: {
              title: "Data & AI",
              description: "I’ve trained journalists in data journalism and helped develop <1>an online platform</1> to explore AI possibilities in journalism.",
              href1: "https://www.journalistiek-en-ai.nl",
            }
          }
        }
      },
      nl: {
        translation: {
          welcome: "Hoi, ik ben ",
          intro: "Op zoek naar leuke, creatieve projecten om samen aan te werken!",
          aboutHeader: "Over mij",
          aboutText: "Ik ben enthousiast over een breed scala aan creatieve projecten. Of het nu gaat om video's, schrijven, websites of iets totaal anders, ik breng een mix van journalistieke skills, creativiteit en iets te veel liefde voor topo-trivia. Laten we samen iets tofs maken!",
          contactHeader: "Neem contact op",
          contactSubheader: "Laten we samenwerken!",
          contactText: "Heb jij iets — van een flard van een idee tot een concreet project — waarvan je nu denkt: Hier wil ik Wouter bij hebben? Stuur me een bericht via het formulier hieronder of mail me op ",
          formName: "Naam",
          formNamePlaceholder: "Je naam",
          formEmail: "Email",
          formEmailPlaceholder: "Je emailadres",
          formMessage: "Bericht",
          formMessagePlaceholder: "Je bericht",
          formSubmit: "Verstuur bericht",
          specialties: {
            video: {
              title: "Video",
              description: "Voor NOS op 3 heb ik ongeveer 100 \"mini-explainers\" gemaakt voor <1>Instagram</1> en TikTok. Ik kan schrijven, filmen en bewerken, en ik oefen ook met motion design."
            },
            writing: {
              title: "Schrijven",
              description: "Ik leg complexe onderwerpen graag op een heldere en boeiende manier uit — of het nu gaat over de <1>stikstofcrisis</1>, de Nederlandse provinciale verkiezingen, Formule 1 of de <2>James Webb Space Telescope</2>."
            },
            web: {
              title: "Web",
              description: "Ik heb viral websites gebouwd zoals <1>Woonplaatsguesser</1> (2 miljoen keer gespeeld in één weekend) en <2>CatGPT</2>, een chatbot die alleen 'miauw' zegt."
            },
            podcast: {
              title: "Podcast",
              description: "Ik ontwikkelde <1>Follow the Facts</1>, over fact-checking, OSINT en sociale media. Momenteel maak en presenteer ik <2>Achter de Pyreneeën</2>, over Spanje en Portugal."
            },
            data_ai: {
              title: "Data & AI",
              description: "Ik heb journalisten getraind in datajournalistiek en geholpen bij de ontwikkeling van <1>journalistiek-en-ai.nl</1>, een project om journalisten wegwijs te maken in de mogelijkheden van AI."
            }
          }
        }
      },
      es: {
        translation: {
          welcome: "¡Hola, soy ",
          intro: "¡Buscando proyectos divertidos y creativos para colaborar contigo!",
          aboutHeader: "Sobre mí",
          aboutText: "Estoy entusiasmado con una amplia gama de proyectos creativos. Ya sea producción de video, escritura, desarrollo web o algo completamente diferente, aporto una mezcla de habilidades periodísticas, creatividad y un amor ligeramente excesivo por la geografía. ¡Hagamos algo increíble juntos!",
          contactHeader: "Ponte en contacto",
          contactSubheader: "¡Trabajemos juntos!",
          contactText: "¿Tienes una idea — ya sea un chispazo o un proyecto completamente desarrollado — en la que piensas: Quiero que Wouter sea parte de esto? Envíame un mensaje usando el formulario a continuación o envíame un correo electrónico a ",
          formName: "Nombre",
          formNamePlaceholder: "Tu nombre",
          formEmail: "Correo electrónico",
          formEmailPlaceholder: "Tu correo electrónico",
          formMessage: "Mensaje",
          formMessagePlaceholder: "Tu mensaje",
          formSubmit: "Enviar mensaje",
          specialties: { 
            video: {
              title: "Video",
              description: "Para NOS op 3, creé alrededor de 100 \"mini-explicadores\" para <1>Instagram</1> y TikTok. Puedo escribir, filmar y editar, y también estoy aprendiendo diseño de movimiento.",
            },
            writing: {
              title: "Escritura",
              description: "Disfruto explicando temas complejos de una manera clara y atractiva — ya sea sobre la <1>crisis del nitrógeno</1>, las elecciones provinciales holandesas, la Fórmula 1 o el <2>Telescopio Espacial James Webb</2>.",
            },
            web: {
              title: "Web",
              description: "He construido sitios web virales como <1>Woonplaatsguesser</1> (jugado 2 millones de veces en un fin de semana) y <2>CatGPT</2>, un chatbot que solo responde con \"miau\".",
            },
            podcast: {
              title: "Podcast",
              description: "Co-creé el pódcast <1>Follow the Facts</1>, sobre verificación de hechos, OSINT y redes sociales. También produzco y co-presento <2>Achter de Pyreneeën</2>, un pódcast sobre España y Portugal.",
            },
            data_ai: {
              title: "Datos e IA",
              description: "He capacitado a periodistas en periodismo de datos y ayudé a desarrollar <1>una plataforma en línea</1> para explorar las posibilidades de la IA en el periodismo.",
            }
          }
        }
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    supportedLngs: ['en', 'nl', 'es'],

  });

export default i18n;