import React from "react";


export const StyledLink = ({ href, children }) => {

return (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-red-600 underline decoration-wavy decoration-teal-400 font-bold hover:decoration-solid"
    style={{ textDecorationSkipInk: 'none' }}
  >
    {children}
  </a>
);
};

export default StyledLink;

