import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

import "tailwindcss-bg-patterns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram, faBluesky } from "@fortawesome/free-brands-svg-icons";

import SpecialtyCard from './components/SpecialtyCard';
import { StyledLink } from "./components/StyledLink";

const ContactEmail = () => {
  const domain = typeof window !== "undefined" ? window.location.hostname.replace("www.", "") : "";
  let email;

  if (domain === "wouter.es") {
    email = "hola@wouter.es";
  } else if (domain === "localhost") {
    email = "test@example.com";
  } else {
    email = "wouter@woutervandijke.nl";
  }

  return (
      <StyledLink href={`mailto:${email}`}>{email}</StyledLink>
  );
};

const specialties = [
  {
    key: "video",
  },
  {
    key: "writing",
  },
  {
    key: "web",
  },
  {
    key: "podcast",
  },
  {
    key: "data_ai",
  },
];

const socialBranding = {
  instagram: {colors: "text-pink-600 hover:text-pink-800", icon: faInstagram, label: "Instagram"},
  bluesky: {colors: "text-blue-500 hover:text-blue-700", icon: faBluesky, label: "Bluesky"},
  linkedin: {colors: "text-blue-600 hover:text-blue-800", icon: faLinkedin, label: "LinkedIn"},
};

const SocialIcon = ({ href, site }) => (
  
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={socialBranding[site].label}
    whileHover={{ scale: 1.3, rotate: 2 }}
  transition={{ type: "spring", stiffness: 300 }}
  className={socialBranding[site].colors}
  >
    <FontAwesomeIcon icon={socialBranding[site].icon} size="2x" />
  </motion.a>
);

export default function FreelancerIntro() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="min-h-screen p-8 bg-white relative overflow-hidden">
      
      <div className="absolute inset-0 pattern-dots pattern-yellow-500 pattern-bg-white pattern-size-8 pattern-opacity-20"></div>
      
      <div className="max-w-2xl mx-auto relative">
        <header className="text-center mb-6">
        
          <h1 className="text-5xl font-extrabold text-gray-800 uppercase tracking-widest">
          {t('welcome')}<span className="text-purple-400">Wouter</span>.
            
          </h1>
          <p className="mt-4 text-xl text-gray-700 italic">
            {t('intro')}
          </p>
          
        </header>

        <section className="mb-6 text-center">
          <motion.div className="relative inline-block" whileHover={{ scale: 1.1, rotate: -2 }}
          whileTap={{ scale: 0.95, rotate: 2 }}
            transition={{ type: "spring", stiffness: 200 }}
            initial={{ scale: .9, rotate: 0 }}
      animate={{ scale: 1, rotate: 2}}>
            <div className="absolute top-2 left-2 w-48 h-48 bg-yellow-300 -z-0"></div>
            <img src="profilepicture.webp" alt="Wouter" className="w-48 h-48 mx-auto border-4 border-gray-800 relative" />
          </motion.div>
          <h2 className="text-3xl font-bold text-gray-800 mt-6">{t('aboutHeader')}</h2>
          <p className="text-lg text-gray-600 mt-4">
            {t('aboutText')}
          </p>
        </section>

        <motion.div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8 max-w-5xl mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {specialties.map((specialty, index) => (
            <SpecialtyCard specialty={specialty} index={index} key={index} />
          ))}
        </motion.div>

        <section className="mb-12">
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">{t('contactHeader')}</h2>
          <p className="text-lg text-gray-800 font-bold">{t('contactSubheader')}</p>

          <p className="text-gray-600 mt-2">
            {t('contactText')}
            <ContactEmail />.
          </p>
          
          <div className="relative block mt-6">
          <div className="absolute top-2 left-2 w-full h-full bg-orange-300 -z-0"></div>

          <div className="relative p-6 bg-white border-4 border-gray-800">
            <form name="contact" method="post">
              <div className="mb-4">
              <input type="hidden" name="form-name" value="contact" />
              <input type="text" hidden name="domain" value={typeof window !== "undefined" ? window.location.hostname.replace("www.", "") : ""} />
                <label htmlFor="name" className="block text-gray-800 font-bold mb-2">{t('formName')}</label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="w-full p-2 border-2 border-gray-800"
                  placeholder={t('formNamePlaceholder')}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-800 font-bold mb-2">{t('formEmail')}</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="w-full p-2 border-2 border-gray-800"
                  placeholder={t('formEmailPlaceholder')}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-gray-800 font-bold mb-2">{t('formMessage')}</label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  className="w-full p-2 border-2 border-gray-800"
                  placeholder={t('formMessagePlaceholder')}
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-red-400 text-gray-800 font-bold py-2 px-4 border-2 border-gray-800 hover:bg-orange-200"
              >
                {t('formSubmit')}
              </button>
            </form>
          </div>
          </div>
        </section>

        <footer className="text-center">
          <div className="flex justify-center space-x-4 mt-4">
          <SocialIcon href="https://www.linkedin.com/in/woutervandijke" site="linkedin"/>
          <SocialIcon href="https://www.instagram.com/woutervdijke" site="instagram"/>
          <SocialIcon href="https://bsky.app/profile/wouter.social" site="bluesky"/>
          </div>

         
        </footer>
        
      </div>
      <div className="absolute right-4 bottom-7">
            <select onChange={(e) => changeLanguage(e.target.value)} className="p-2 border bg-white appearance-none">
              <option value="en" selected={i18n.language === 'en' ? 'selected' : ''}>🇬🇧</option>
              <option value="nl" selected={i18n.language === 'nl' ? 'selected' : ''}>🇳🇱</option>
              <option value="es" selected={i18n.language === 'es' ? 'selected' : ''}>🇪🇸 </option>
            </select>
          </div>
    </div>
  );
}
