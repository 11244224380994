import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useTranslation, Trans} from 'react-i18next';

import { motion } from 'framer-motion';

import StyledLink from './StyledLink';

const backgroundColors = ["bg-red-400", "bg-pink-300", "bg-teal-400", "bg-yellow-300", "bg-purple-300", "bg-orange-300"];

const SpecialtyCard = ({ specialty, index }) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const { t, i18n } = useTranslation();

  return (
    <motion.div
      ref={ref}
      className="relative text-center z-10"
      initial={{ scale: 0.95, rotate: -2 }}
      animate={inView ? { scale: 1, rotate: 0 } : {}}
      transition={{ duration: 0.5, type: "spring", stiffness: 300 }}
      whileHover={{ scale: 1.05, rotate: 2 }}
      whileTap={{ scale: 0.95, rotate: 2 }}
    >
      <motion.div
        className={`absolute top-2 left-2 w-full h-full -z-30 ${backgroundColors[index % backgroundColors.length]}`}
      ></motion.div>
      <div className="p-4 border-4 border-gray-800 w-full h-full bg-white text-center z-10">
        <h2 className="text-xl font-bold text-gray-800 mb-2 uppercase relative z-10">
        {t(`specialties.${specialty.key}.title`)}
        </h2>
        <p className="text-gray-700 relative z-10">
          <Trans i18nKey={`specialties.${specialty.key}.description`} 
          components={{ 1: <StyledLink href={t(`specialties.${specialty.key}.href1`)} />, 
          2: <StyledLink href={t(`specialties.${specialty.key}.href2`)} /> }} />
          </p>
      </div>
    </motion.div>
  );
};

export default SpecialtyCard;